












import Vue from 'vue';

interface IData {
    monsterId: number|null;
}

export default Vue.extend({
    components: {
    },
    data(): IData {
        return {
            monsterId: null,
        };
    },
    computed: {
        valid(): boolean {
            return !isNaN(Number(this.monsterId)) && !!this.monsterId && this.monsterId > 0;
        },
    },
    methods: {
        loadMonster() {
            this.$router.push(`/monsters/${this.monsterId}`);
        }
    }
});
